// Dependencies
import React from "react";

const LogoPro = ({ width, height, color, waveColor }) => {
  return (
    <svg
      width={width ? width : "162px"}
      height={height ? height : "107px"}
      viewBox="0 0 162 107"
    >
      <g id="Design-System" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group-28-Copy">
          <g id="Group" fill-rule="nonzero">
            <g transform="translate(0.000000, 9.927191)" fill="#005773">
              <path d="M0,0 L6.2741573,0 L6.2741573,23.7995506 C8.72629213,19.4004494 13.9182022,16.7316854 19.1110112,16.7316854 C28.8476404,16.7316854 34.3289888,23.222472 34.3289888,34.1851685 C34.3289888,49.0408988 24.087191,53.8732584 14.2076404,53.8732584 C9.15865169,53.8732584 4.32719101,52.7910112 0,51.3483147 L0,0 Z M14.7838203,47.9595506 C21.8507866,47.9595506 27.8364045,44.2813483 27.8364045,34.6175281 C27.8364045,26.7568539 24.0152809,22.7182023 17.2350561,22.7182023 C13.4121348,22.7182023 10.0952809,25.025618 8.07550561,27.7662921 C6.99505618,29.2808988 6.41707866,31.6602247 6.41707866,34.3289887 L6.41707866,46.4449438 C8.36494382,47.2386517 10.8170787,47.9595506 14.7838203,47.9595506 Z" id="Shape"></path>
              <path d="M51.6898876,46.7334832 C51.4741573,46.0844944 51.4022472,44.8584269 51.4022472,43.6332584 L51.4022472,22.8620224 L60.6597752,22.8620224 L59.3617978,17.5973033 L51.4022472,17.5973033 L51.4022472,4.4 L45.1280899,6.96179775 L45.1280899,43.8480899 C45.1280899,46.661573 45.2710112,48.7523596 45.9208988,50.1950562 C47.1469663,52.9357303 49.4543821,53.8732584 52.7002248,53.8732584 C54.5024719,53.8732584 56.0889888,53.512809 57.4588764,52.9357303 L57.4588764,47.1658427 C56.3047191,47.6710112 55.0795506,47.9595506 54.2858427,47.9595506 C52.7002248,47.9595506 52.051236,47.5271911 51.6898876,46.7334832 Z" id="Path"></path>
            </g>
            <path d="M99.9577528,57.0049439 C97.9173034,53.5613484 97.2152809,49.5442697 97.2152809,45.1442697 C97.2152809,35.7069663 102.380225,24.930337 113.986517,24.930337 C119.088539,24.930337 122.276854,26.0782022 124.381124,26.9069663 L124.381124,32.327191 C121.957753,31.2431461 118.769438,30.0952809 114.879101,30.0952809 C106.01618,30.0952809 103.719551,37.1730337 103.082247,42.0197752 C102.954607,43.1676404 102.954607,44.2516854 102.954607,45.3357303 C104.613034,41.7006742 109.586517,39.4049439 114.114157,39.4049439 C120.873708,39.4049439 127.441798,43.1676404 127.441798,51.2017978 C127.441798,59.4283146 120.554607,63.8921348 112.838652,63.8921348 C106.717303,63.891236 102.381124,61.0858427 99.9577528,57.0049439 Z M121.702472,51.010337 C121.702472,46.8656179 118.769438,43.7402248 112.967191,43.7402248 C107.227865,43.7402248 103.78427,47.5667416 103.78427,51.010337 C103.78427,55.7932584 108.439551,58.5986521 112.520449,58.5986521 C118.451236,58.5995506 121.702472,55.2826966 121.702472,51.010337 Z" id="Shape" fill="#005773"></path>
            <path d="M144.547416,63.9559551 C135.110112,63.9559551 129.94427,55.4750561 129.94427,44.3793258 C129.94427,33.2197752 136.193258,24.8665169 144.801797,24.8665169 C154.431461,24.8665169 159.532584,33.2197752 159.532584,44.3793258 C159.532584,55.4741573 154.11236,63.9559551 144.547416,63.9559551 Z M144.801797,30.1582022 C138.106966,30.1582022 135.619775,37.2997752 135.619775,44.4422472 C135.619775,51.52 137.915506,58.7262921 144.738876,58.7262921 C151.562248,58.7262921 153.794157,51.5208988 153.794157,44.4422472 C153.794157,37.1092134 151.688988,30.1582022 144.801797,30.1582022 Z" id="Shape" fill="#005773"></path>
            <g transform="translate(56.061929, 0.000000)" fill="#F3B01C" id="Path">
              <path d="M0.317396725,21.2080899 C0.317396725,21.2080899 -3.08664822,9.96134831 12.4009922,10.131236 C21.7609923,10.3020225 28.228408,16.7775281 45.5875091,14.3919101 C62.9466102,12.0062921 70.4351496,3.82651685 70.4351496,3.82651685 C70.4351496,3.82651685 53.9263854,21.2080899 29.7600933,20.3559551 C22.4009923,20.0970787 9.84818324,16.7775281 0.317396725,21.2080899 Z"></path>
              <path d="M26.8333518,11.301573 C28.817172,11.8103371 30.969981,12.2525843 33.3915541,12.5177528 C43.0311045,7.88314607 47.9182956,0.0782022472 47.9704305,0 C40.6679586,8.80808988 26.9672844,11.2826966 26.8333518,11.301573 Z"></path>
            </g>
            <path d="M87.0759551,43.1676404 L86.8206742,43.04 C90.7110112,42.2750561 93.7707866,39.1505618 93.7707866,34.6867416 C93.7707866,27.1622472 86.5653933,24.930337 81.3995506,24.930337 C77.0022472,24.930337 73.4507866,26.101573 71.3761797,26.9743821 L69.8624719,33.0265169 C71.3339325,32.3226966 76.1114607,30.1591012 80.2516854,30.1591012 C83.44,30.1591012 87.6485393,31.498427 87.6485393,36.0260675 C87.6485393,39.2143821 84.5240449,41.701573 78.5941573,41.701573 L78.5941573,45.5919101 C83.6314607,45.5919101 88.3505618,47.0588764 88.3505618,51.7770787 C88.3505618,56.8782022 84.269663,58.6642697 80.698427,58.6642697 C76.2346067,58.6642697 71.4516854,56.7505618 69.0930337,55.730337 L69.0930337,61.1505618 C71.1973034,62.1069663 75.2143821,63.8930337 81.9110112,63.8930337 C86.0557303,63.8930337 94.7280899,61.5973034 94.7280899,52.6058427 C94.7280899,47.7582022 91.6674157,44.1231461 87.0759551,43.1676404 Z" id="Path" fill="#005773"></path>
          </g>
          <text id="Titulo" font-family="Averta-Bold" font-size="40" font-weight="normal" fill="#F3B01C">
            <tspan x="97" y="98">Pro</tspan>
          </text>
        </g>
      </g>
    </svg>
  );
};

export default LogoPro;
