import { useRef, useEffect } from "react";
import PermissionCircle from "./permissionCircle";
import Icon from "../../components/icons";

const HeroCard = ({ item, lang, setTooltip }) => {

  const heroCardRef = useRef(null);

  return (
    <div 
      ref={heroCardRef}
      className="card small in-grid"
      onClick={() => setTooltip(item, heroCardRef)}
      style={{cursor: item.tooltip ? 'pointer' : 'default'}}
    >
      <p className="card-number">
        {Object.values(item)[0].amount}
      </p>
      <p className="card-text small o-color--blue-dark">{Object.values(item)[0].title}</p>


      <div style={{ position: "absolute", right: 4, top: 4, zIndex: 2, cursor: "pointer" }}>
        {Object.values(item)[0].hasOwnProperty("see_actives_permission") && (
          <Icon name="InfoTooltip" noWrapper={true} />
        )}
      </div>

    </div>
  )
}

export default HeroCard
