import { useRef, useState, useEffect, memo, useCallback } from "react";
import Icon from "../../components/icons";
import { connect } from "react-redux";
import classNames from "classnames";

import BarsGraphic from "../../components/barsGraphic";
import LineHighChart from "../../components/LineHighChart";
import NestedListOwnership from "../../components/nestedListOwnership";
import Arrow from "../../components/icons/arrow";
import PieHighcharts from "../../components/PieHighcharts";
import Loading from "../../components/loading";
import TableRecentOrdersWithLink from "../../components/TableRecentOrdersWithLink";
import AlertModal from "../../components/alertModal";
import WealthMap from "./wealthMap";

import { selectUser } from "../../actions/profile";
import { getFilteredDataBarsGraph, createFavorite, destroyFavorite, createNote, destroyNote } from "../../actions/dashboard";
import { getRecentOrdersActivities } from "../../actions/balance";
import {postCleanNotificationsAdeppar} from '../../actions/financial_statements'

const DashboardTable = (props) => {
  const { data: tableDataProp } = props;

  const [filteredDataBarsGraph, setFilteredDataBarsGraph] = useState({});
  const [financialAssetEvolution, setFinancialAssetEvolution] = useState("consolidated");
  const [filtersInternalization, setFiltersInternalization] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [netWorthEvolutionLoading, setNetWorthEvolutionLoading] = useState(false);
  const [tableData, setTableData] = useState(tableDataProp);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1); // Track which row's graph is selected
  const [currentUser, setCurrentUser] = useState({});
  const [userForChange, setUserForChange] = useState({});
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const tableWrapperRef = useRef(null)
  const tableRef = useRef(null)
  const tableHeaderRowRef = useRef(null)
  const rowRef = useRef([])
  const rowContentRef = useRef([])
  const rowChevronRef = useRef([])
  const cellHeaderRef = useRef([])
  const starRef = useRef([])
  const notesRef = useRef('')

  useEffect(() => {
    props.getRecentOrdersActivities(
      props.authentication_token,
      "?page=1",
      (responseJSON) => successRecentOrderesActivity(responseJSON),
      () => tableRecentOrdersError(),
    );
    console.log("PROPS table", props)
  }, []);

  // Store scroll listener references using useRef to persist them across renders
  const wrapperScrollListenerRef = useRef(null);
  const windowScrollListenerRef = useRef(null);

  useEffect(() => {
    handleTableHeaderRowBehavior({ resize: false})

    const handleTableHeaderRowBehaviorListener = () => handleTableHeaderRowBehavior({resize: true})

    window.addEventListener( "resize", handleTableHeaderRowBehaviorListener )

    // Cleanup
    return () =>  {
      window.removeEventListener( "resize", handleTableHeaderRowBehaviorListener )
      window.removeEventListener("scroll", windowScrollListenerRef.current);

      if(tableWrapperRef.current) {
        tableWrapperRef.current.removeEventListener("scroll", wrapperScrollListenerRef.current);
      }

      let existingClone = document.querySelector('.is-cloned');
      if (existingClone) {
        document.body.removeChild(existingClone);
      }
    }
  }, [])


  const handleTableHeaderRowBehavior =  ({ resize }) => {
    const table = tableRef.current;

    // Only proceed if the table and wrapper exist
    if (table && tableWrapperRef.current) {
      const wrapper = tableWrapperRef.current;

      // Check if a clone already exists to prevent multiple clones
      let existingClone = document.querySelector('.is-cloned');

      if (resize) {
        existingClone.style.left = `${wrapper.getBoundingClientRect().left}px`;
      }

      if (!existingClone) {
        const wrapperClone = wrapper.cloneNode(true);

        // Remove body from cloned wrapper
        const bodyClone = wrapperClone.querySelector(".c-table-dashboard__body");
        bodyClone.remove();

        wrapperClone.classList.add("is-cloned")

        wrapperClone.style.width = `${wrapper.getBoundingClientRect().width}px`;
        wrapperClone.style.left = `${wrapper.getBoundingClientRect().left}px`;

        document.body.appendChild(wrapperClone);

        // Get cloned cells
        const clonedChevrons = [...wrapperClone.querySelectorAll(".c-table-dashboard__cell-sort")];

        // Attach event to cloned cells
        tableData && tableData.length > 0 && Object.keys(tableData[0]).forEach((item, index) => {
          clonedChevrons.forEach(el => {
            // We only attach the event when the cloned cell matches the original one
            if (el.dataset.item === item) {
              el.addEventListener("click", (e) => handleSort(e, item, index));
            }
          });
        });

        // Listen horizontal scroll
        const handleWrapperScrollListener = () => handleWrapperScroll(wrapper, wrapperClone); // listener to removeEventListener
        const handleWindowScrollListener = () => handleWindowScroll(table, wrapperClone); // listener to removeEventListener

        wrapper.addEventListener("scroll", handleWrapperScrollListener);
        window.addEventListener("scroll", handleWindowScrollListener);

        wrapperScrollListenerRef.current = handleWrapperScrollListener;
        windowScrollListenerRef.current = handleWindowScrollListener;
      }
    }

  }

  const handleWindowScroll = (table, wrapperClone) => {
    const tableTop = table.getBoundingClientRect().top;
    if (tableTop <= 0.5) {
      wrapperClone.style.opacity = 1;
      wrapperClone.style.pointerEvents = 'all';
    } else {
      wrapperClone.style.opacity = 0;
      wrapperClone.style.pointerEvents = 'none';
    }
  }

  const handleWrapperScroll = (wrapper, wrapperClone) => {
    const leftPos = wrapper.scrollLeft
    wrapperClone.scrollLeft = leftPos
  }

  const successRecentOrderesActivity = (responseJSON) => {
    setTotalPages(responseJSON.total_pages);
    setCurrentPage(responseJSON.page);
    setFiltersInternalization(responseJSON.internalization);
  };

  const tableRecentOrdersError = () => {
    console.log("TableRecentOrders error");
  };

  const filteredCall = (params) => {
    goToPage(params);
  };

  const onChangeNote = (e) => {
    notesRef.current = e.target.value
  }

  const onSaveNote = (index) => {
    setIsLoading(true)
    const id = tableDataProp[index].id
    props.createNote(
      props.authentication_token,
      (result) => updateNoteInTable(index, notesRef.current),
      (error) => console.log("error", error),
      id,
      notesRef.current
    )
  }

  const onDestroyNote = (index) => {
    setIsLoading(true)
    const id = tableDataProp[index].id
    props.destroyNote(
      props.authentication_token,
      (result) => updateNoteInTable(index, ''),
      (error) => console.log("error", error),
      id,
      notesRef.current
    )
  }

  const updateNoteInTable = (index, content) => {
    // Perform a shallow copy of the entire array
    const newTableTemp = [...tableData];

    // Clone the specific object, ensuring a new reference for the nested `content` object
    newTableTemp[index] = {
      ...newTableTemp[index], 
      content: { 
        ...newTableTemp[index].content, 
        notes: content
      } 
    };

    // Set the updated array as the new state
    setTableData(newTableTemp);
    setIsLoading(false)
    notesRef.current = content
  }

  const goToPage = (params) => {
    if (params !== "") {
      props.getRecentOrdersActivities(
        props.authentication_token,
        params,
        (responseJSON) => successRecentOrderesActivity(responseJSON),
        () => tableRecentOrdersError(),
      );
    }
  };

  const showCloseContent = (index) => {
    // if there is not an index, close all contents opened
    // because we are sorting the table.
    if(index === null || index === undefined) {
      rowContentRef.current.forEach(el => {
        el.classList.remove("is-active")
      })
      rowChevronRef.current.forEach(el => {
        el.classList.remove("is-active")
      })
      return false;
    }

    // if there is an index close and open the content
    if (rowContentRef.current[index].classList.contains("is-active")) {
      rowContentRef.current[index].classList.remove("is-active");
      rowChevronRef.current[index].classList.remove("is-active");
    } else {
      rowContentRef.current[index].classList.add("is-active");
      rowChevronRef.current[index].classList.add("is-active");
    }
  };

  const ownershipAction = (id, url) => {
    if (url.indexOf("https://") != -1 || url.indexOf("http://") != -1) {
      window.location = url;
    }
  };

  const updateNetWorthData = (userId, index) => {
    filteredBarsFinancialAction(
      `balance_principal/net_worths?frecuency=month&times=10&user_id=${userId}&bulltick_pro=true`,
      index,
    );
  };

  const filteredBarsFinancialAction = (url, index) => {
    setNetWorthEvolutionLoading(true);
    props.getFilteredDataBarsGraph(
      props.authentication_token,
      url,
      (responseJson) => successFilteredBars(responseJson, index),
      () => errorFilteredBars(),
    );
  };

  const successFilteredBars = (data, index) => {
    setSelectedRowIndex(index);
    setFilteredDataBarsGraph(data.balance_index.bars_graphic_data);
    setNetWorthEvolutionLoading(false);
  };

  const errorFilteredBars = () => {
    console.log("filtered bars graph error");
  };

  const pieSelected = (pie, nested) => {
    console.log(pie, nested);
  };

  const handleSort = (e, item, index) => {
    const isActive = e.currentTarget.classList.contains("is-active")
    const sortOrder = isActive ? "descending" : "ascending"

    showCloseContent()
    rotateSortArrow(index, item);

    const sortedTableData = [...tableData].sort((a, b) => {
      const aVal = a[item];
      const bVal = b[item];

      // Handle numeric sorting
      if (!isNaN(aVal) && !isNaN(bVal)) {
        return sortOrder === 'ascending' ? Number(aVal) - Number(bVal) : Number(bVal) - Number(aVal);
      }

      if (['assets', 'net_worth', 'liabilities', 'financial_assets'].includes(item)) {
        // Normalize dollar amounts
        const aValWithDollar = parseFloat(aVal.value.split("$")[1].replace(/,/g, ''));
        const bValWithDollar = parseFloat(bVal.value.split("$")[1].replace(/,/g, ''));
        return sortOrder === 'ascending' ? aValWithDollar - bValWithDollar : bValWithDollar - aValWithDollar;
      }

      // Handle string sorting
      return sortOrder === 'ascending' ? aVal.localeCompare(bVal) : bVal.localeCompare(aVal);
    });

    setTableData(sortedTableData);
  };

  const rotateSortArrow = (index, item) => {
    const allArrows = [...document.querySelectorAll('.c-table-dashboard__cell-sort')]
    allArrows.forEach(el => {
      if(el.dataset.item === item) {
        el.classList.toggle("is-active")
      } else {
        el.classList.remove("is-active")
      }
    })
  }

  const handleChangeUser = (item) => {
    if(item.id == currentUser.id) return false;
    setUserForChange(item)
    setShowModalConfirmation(true)
  }

  const changeUser = () => {
    props.selectUser(
      props.authentication_token,
      () => changeUserSuccess(),
      () => console.log("error"),
      userForChange.id
    );
  }

  const changeUserSuccess = () => {
    window.location.reload()
    setShowModalConfirmation(false)
  }

  useEffect(() => {
    const currentUser = props.assigned_users.find(user => user.current)
    setCurrentUser(currentUser)
  }, [])

  const handleFavorite = (item, index) => {

    starRef.current[index].classList.add("is-loading")

    if(item.is_favorite) {
      props.destroyFavorite(
        props.authentication_token, 
        (data) => destroyFavoriteSuccess(data, index), 
        (error) => destroyFavoriteError(error, index),
        item.id
      )
    } else {
      props.createFavorite(
        props.authentication_token, 
        (data) => createFavoriteSuccess(data, index), 
        (error) => createFavoriteError(error, index),
        item.id
      )
    }
  }

  const createFavoriteSuccess = (data, index) => {
    setTableData([...data.clients_table]);
    starRef.current[index].classList.remove("is-loading")
  } 

  const createFavoriteError = (error, index) => {
    console.log("Create favorite error", error)
    starRef.current[index].classList.remove("is-loading")
  } 

  const destroyFavoriteSuccess = (data, index) => {
    setTableData([...data.clients_table]);
    starRef.current[index].classList.remove("is-loading")
  } 

  const destroyFavoriteError = (error, index) => {
    console.log("Destroy favorite error", error)
    starRef.current[index].classList.remove("is-loading")
  } 

  const handleHeaderCellClassNames = (index) => {
    return classNames(
      "c-table-dashboard__cell",
      index === 1 && "is-first"
    )
  }

  return (
    <>
      <div className="c-table-dashboard__wrapper" key={"table-dashboard"} ref={tableWrapperRef}>
        <div className="c-table-dashboard" ref={tableRef}>
          <div className="c-table-dashboard__header">
            <div className="c-table-dashboard__row" ref={tableHeaderRowRef}>
              {tableData &&
                tableData.length > 0 &&
                Object.keys(tableData[0]).map((item, index) => {
                  console.log("ITEM", item)
                  const title = item.includes("_")
                    ? item.split("_").join(" ")
                    : item;
                  if (item !== "content" && item !== 'id' && index !== 0) {
                    return (
                      <div 
                        className={handleHeaderCellClassNames(index)}
                        key={`${index}-head-item`}
                        ref={(el) => (cellHeaderRef.current[index] = el)}
                      >
                        <span>
                          {title.includes("favorite") 
                            ? "Client" 
                            : item === "id_bulltick" 
                              ? "Bulltick ID" 
                              : item === "addepar" 
                                ? "ADDEPAR Access" 
                                : item === "bt360" 
                                  ? "Acceptance Bulltick Access"
                                  : title
                          }
                        </span>
                        {(item === "name" ||
                          item === "id" ||
                          item === "net_worth" ||
                          item === "assets" ||
                          item === "liabilities" ||
                          item === "financial_assets") && (
                            <div
                              className="c-table-dashboard__cell-sort"
                              data-item={item}
                              onClick={(e) => handleSort(e, item, index)}
                            >
                              <Icon name="SingleArrow" orientation={"down"} />
                            </div>
                          )}
                      </div>
                    );
                  }
                })}
            </div>
          </div>

          <div className="c-table-dashboard__body">
            {tableData && tableData.length > 0 && tableData.map((item, indexRow) => {

              return (
                <>
                  {/* Row */}
                  <div
                    key={`${indexRow}-row`}
                    className="c-table-dashboard__row"
                    ref={(el) => (rowRef.current[indexRow] = el)}
                  >
                    {Object.entries(item).map(([key, value], index) => {
                      // If it's not content we display a table cell
                      if (key !== "content" && key !== "id" && index !== 0 ) {
                        if (index === 1) {
                          return (
                            <div key={`${index}-body-item`} className="c-table-dashboard__cell is-first">
                              <span 
                                className="c-table-dashboard__cell-change-user" 
                                onClick={() => {
                                  handleChangeUser(item)
                                }}
                              >
                                <Icon 
                                  name="ChangeUser" 
                                  style={{ 
                                    opacity: item.id == currentUser.id ? .3 : 1,
                                    cursor:  item.id == currentUser.id ? "auto" : "pointer"
                                  }}
                                />
                              </span>

                              <div className="c-table-dashboard__cell-first-content">
                                <div
                                  className="c-table-dashboard__cell-chevron"
                                  ref={(el) => (rowChevronRef.current[indexRow] = el)}
                                  onClick={() => {
                                    showCloseContent(indexRow);
                                    setSelectedRowIndex(indexRow);
                                    updateNetWorthData(item.id, indexRow);
                                  }}
                                >
                                  <Icon name="SingleArrow" orientation={"down"} />
                                </div>
                                <span>
                                  {/* {indexRow + 1} */}
                                  { value }
                                </span>
                                <span 
                                  ref={(el) => (starRef.current[indexRow] = el)}
                                  className="c-table-dashboard__cell-star"
                                  style={{ opacity: item.is_favorite ? 1 : .3}}
                                  onClick={() => handleFavorite(item, indexRow)}
                                >
                                  <Icon name="Star" color="#f3b01c" />
                                </span>
                              </div>
                            </div>
                          );
                        } else if (value.url || value.url === '') {
                          return (
                            <div
                              className="c-table-dashboard__cell"
                              key={`${index}-body-item`}
                            >
                              {value.url !== '' && (
                                <a href={value.url} target="_blank">
                                  <Icon name="Eye" />
                                </a>
                              )}
                            </div>
                          );
                        } else if (value === true) {
                          return (
                            <div
                              className="c-table-dashboard__cell"
                              key={`${index}-body-item`}
                            >
                              <a
                                href={value.url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Icon name="Check" />
                              </a>
                            </div>
                          );
                        } else if (value === false) {
                          return (
                            <div
                              className="c-table-dashboard__cell"
                              key={`${index}-body-item`}
                            >
                              <a
                                href={value.url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Icon name="Close" color="#f3b01c" />
                              </a>
                            </div>
                          );
                        } else if (value.value) {
                          return (
                            <div
                              className="c-table-dashboard__cell"
                              key={`${index}-body-item`}
                            >
                              <span style={{ flex: 1 }}>{value.value}</span>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              className="c-table-dashboard__cell"
                              key={`${index}-body-item`}
                            >
                              <span>{value}</span>
                            </div>
                          );
                        }
                      }
                    })}
                  </div>
                  {/* @Row */}

                  {/* Row content */}
                  <div
                    key={`${indexRow}-row-content`}
                    className="c-table-dashboard__row-content-wrapper"
                    ref={(el) => (rowContentRef.current[indexRow] = el)}
                  >
                    <div className="c-table-dashboard__row-content">
                      {Object.entries(item).map(([key, value], index) => {

                        if (key === "content") {
                          return (
                            <>
                              <div className="c-table-dashboard__row-content-charts">
                                <div style={{ width: "48%" }}>
                                  <h2 className="headingH2">
                                    { value.net_worth_data.bars_graphic_data.title }
                                  </h2>

                                  {(Object.keys( value.net_worth_data.bars_graphic_data,).length === 0 || netWorthEvolutionLoading) && (
                                    <Loading
                                      simple={true}
                                      text={props.internalization.loading}
                                    />
                                  )}

                                  {Object.keys( value.net_worth_data.bars_graphic_data,).length > 0 && (
                                    <BarsGraphic
                                      data={
                                        selectedRowIndex === indexRow && Object.keys(filteredDataBarsGraph).length > 0
                                          ? filteredDataBarsGraph
                                          : value.net_worth_data.bars_graphic_data
                                      }
                                      filterAction={(url) =>
                                        filteredBarsFinancialAction(
                                          url,
                                          indexRow,
                                        )
                                      }
                                      xTickValues={
                                        selectedRowIndex === indexRow &&
                                          Object.keys(filteredDataBarsGraph)
                                            .length > 0
                                          ? filteredDataBarsGraph.type.pasive.map(
                                            (elem) => elem.x,
                                          )
                                          : value.net_worth_data.bars_graphic_data.type.pasive.map(
                                            (elem) => elem.x,
                                          )
                                      }
                                      yTickValues={
                                        selectedRowIndex === indexRow &&
                                          Object.keys(filteredDataBarsGraph)
                                            .length > 0
                                          ? filteredDataBarsGraph.type.pasive.map(
                                            (elem) => elem.y,
                                          )
                                          : value.net_worth_data.bars_graphic_data.type.pasive.map(
                                            (elem) => elem.y,
                                          )
                                      }
                                    />
                                  )}
                                </div>

                                {value.consolidated_data.consolidated_graph && value.custodian_data.custodian.graph_all &&
                                  Object.keys(value.custodian_data.custodian.graph_custodian).length > 0 && (
                                    <div style={{ width: "48%" }}>
                                      <h2 className="headingH2">
                                        Financial Assets Evolution
                                      </h2>
                                      <div
                                        className="filter-buttons-wrapper"
                                        style={{ marginTop: 36 }}
                                      >
                                        {value.consolidated_data
                                          .consolidated_graph && (
                                            <button
                                              className={`filter-buttons btn ${financialAssetEvolution === "consolidated" ? "primary" : "primary border"}`}
                                              type="button"
                                              onClick={() =>
                                                setFinancialAssetEvolution(
                                                  "consolidated",
                                                )
                                              }
                                            >
                                              <span className="btn-text">
                                                Consolidated
                                              </span>
                                            </button>
                                          )}

                                        {value.custodian_data.custodian.graph_all && Object.keys( value.custodian_data.custodian.graph_custodian ).length > 0 && (
                                          <button
                                            className={`filter-buttons btn ${financialAssetEvolution === "custodian" ? "primary" : "primary border"}`}
                                            type="button"
                                            onClick={() =>
                                              setFinancialAssetEvolution(
                                                "custodian",
                                              )
                                            }
                                          >
                                            <span className="btn-text">
                                              Custodian
                                            </span>
                                          </button>
                                        )}
                                      </div>

                                      {financialAssetEvolution === "consolidated" && value.consolidated_data.consolidated_graph && (
                                          <LineHighChart
                                            styles={{ paddingTop: 0 }}
                                            key={"graph_t"}
                                            type="account"
                                            updateData={(itemsSelected, data_temp) => 
                                              console.log( itemsSelected, data_temp)
                                            }
                                            currentData={
                                              value.consolidated_data.consolidated_graph.last_month
                                            }
                                            dates={
                                              value.consolidated_data.consolidated_graph.dates
                                            }
                                            allCustodians={{}}
                                            accountData={
                                              value.consolidated_data.consolidated_graph
                                            }
                                            currentCustodianName={
                                              props.user.language.includes("es")
                                                ? "Consolidado"
                                                : "Consolidated"
                                            }
                                            centerLabel={true}
                                            languageUserAgent={
                                              props.user.language
                                            }
                                            yAxisTitle={
                                              props.user.language.includes("es")
                                                ? "Rendimiento"
                                                : "Performance"
                                            }
                                            setFilterType={(type) =>
                                              console.log(type)
                                            }
                                            extra_title={{}}
                                          />
                                        )}

                                      {financialAssetEvolution === "custodian" && value.custodian_data.custodian.graph_all && Object.keys( value.custodian_data.custodian.graph_custodian).length > 0 && (
                                          <LineHighChart
                                            styles={{ paddingTop: 0 }}
                                            key={"graph_t_custodian"}
                                            type="custodian"
                                            allSelected={
                                              value.custodian_data.custodian
                                              .custodian_all_name
                                            }
                                            preSelected={false}
                                            updateData={(
                                              itemsSelected,
                                              data_temp,
                                            ) => ""}
                                            currentData={
                                              value.custodian_data.custodian
                                              .graph_custodian.dates
                                              .last_month
                                            }
                                            dates={
                                              value.custodian_data.custodian
                                              .graph_custodian.dates
                                            }
                                            allCustodians={
                                              value.custodian_data.custodian
                                              .graph_all
                                            }
                                            currentCustodianName={""}
                                            centerLabel={true}
                                            languageUserAgent={
                                              props.user.language
                                            }
                                            yAxisTitle={
                                              props.user.language.includes("es")
                                                ? "Rendimiento"
                                                : "Performance"
                                            }
                                            extra_title={
                                              value.custodian_data.custodian
                                              .extra_title
                                            }
                                          />
                                        )}
                                    </div>
                                  )}
                              </div>


                              <div className="c-table-dashboard__row-content-charts" style={{ flexDirection: "column", gap: 0, marginBottom: 32}}>
                                <h2 className="headingH2">
                                  {value.wealth_map.title}
                                </h2>
                                {value.wealth_map.graph.nodeDataArray.length > 0 && (
                                  <WealthMap location={props.location} history={props.history} wealthMapData={value.wealth_map} />
                                )}
                                {value.wealth_map.graph.nodeDataArray.length < 1 && (
                                  <p style={{ marginTop: 32, textAlign: "center" }}> No data </p>
                                )}
                              </div>

                              <div className="c-table-dashboard__row-content-charts">
                                <div style={{ flex: 1, marginBottom: 40 }}>
                                  <h2
                                    className="headingH2"
                                    style={{ marginBottom: 36 }}
                                  >
                                    {value.activity.title}
                                  </h2>
                                  {Object.keys(filtersInternalization).length > 0 && (
                                    <TableRecentOrdersWithLink
                                      dataTable={ value.activity.data.rows }
                                      filters_internalization={ filtersInternalization }
                                      filteredCall={(params) => filteredCall(params) }
                                    />
                                  )}
                                </div>
                              </div>

                              <div className="c-table-dashboard__row-content-charts">
                                <div
                                  style={{
                                    maxHeight: 300,
                                    overflow: "auto",
                                    flex: 1,
                                  }}
                                >
                                  <h2
                                    className="headingH2"
                                    style={{ marginBottom: 36 }}
                                  >
                                    Ownership Structure
                                  </h2>
                                  {value.ownership_data.ownership_structure
                                    .data[0].descendants.length > 0 ? (
                                      <NestedListOwnership
                                        mainClass={"nestedlist--padding"}
                                        color={"nestedlist__item--grey"}
                                        data={
                                          value.ownership_data.ownership_structure
                                          .data
                                        }
                                        ownership={true}
                                        action={(id, url) =>
                                          ownershipAction(id, url)
                                        }
                                        levelColors={true}
                                        square={"nestedlist__item--square"}
                                      />
                                    ) : (
                                      <p>
                                        {
                                          value.ownership_data.ownership_structure
                                          .no_data_title
                                        }
                                      </p>
                                    )}
                                </div>
                                <div style={{ flex: 1 }}>
                                      <h2 className="headingH2" style={{ marginBottom: 36 }}>{ value.pie_high_chart.title }</h2>
                                  <div
                                    style={{
                                      height: 222,
                                      maxWidth: 600,
                                      margin: "0 auto",
                                      overflowY: "hidden",
                                    }}
                                  >
                                    <div
                                      style={{ transform: "translateY(-37%)" }}
                                    >
                                      <PieHighcharts
                                        pieSelected={(pie) => pieSelected(pie)}
                                        data={ value.pie_high_chart.graph.pie_graphic_assets.data }
                                        size={112}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* Info */}
                              <div className="c-table-dashboard__row-content-charts" style={{ flexDirection: "column"}}>
                                <h2 className="headingH2">{ value.loaded_items.title }</h2>
                                <div
                                  style={{
                                    paddingBottom: 24,
                                  }}
                                >
                                  <div
                                    className="c-table-dashboard__loaded-items"
                                    style={{
                                      display: "grid",
                                      gridTemplateColumns:
                                      "repeat(3, minmax(250px, 1fr))",
                                    }}
                                  >

                                    {value.loaded_items.data.map((item) => {
                                      return (
                                        <p
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            height: 24,
                                          }}
                                        >
                                          <strong> {Object.keys(item)[0]}: </strong>
                                          {Object.values(item)[0] === true 
                                            ? ( <Icon name="Check" width={14} />) 
                                            : ( <Icon name="Close" width={14} color="#f3b01c" />)
                                          }
                                        </p>
                                      );
                                    })}
                                  </div>
                                </div>
                                {(value.notes !== undefined || value.notes !== null || value.notes === '') && (
                                  <div className="c-table-dashboard__row-content-charts" style={{ flexDirection: "column"}}>
                                    <h2 class="headingH2">Notes</h2>
                                    {isLoading && (
                                      <Loading
                                        simple={true}
                                        text={props.internalization.loading}
                                        style={{ width: 90, margin: "0 auto"}}
                                      />
                                    )}
                                    {!isLoading && (
                                      <textarea 
                                        rows="10" 
                                        onChange={e => onChangeNote(e)}
                                      >
                                        {value.notes}
                                      </textarea>
                                    )}
                                    <div className="wrapper-tabs">
                                      <button 
                                        type="button" 
                                        className="btn secondary" 
                                        onClick={() => onDestroyNote(indexRow)} 
                                        style={{ width: 'fit-content' }}
                                      >
                                        <span className="btn-text">Eliminar</span>
                                      </button>
                                      <button 
                                        type="button" 
                                        className="btn primary" 
                                        onClick={() => onSaveNote(indexRow)} 
                                        style={{ width: 'fit-content' }}
                                      >
                                        <span className="btn-text">Guardar</span>
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          );
                        }
                      })}
                    </div>
                  </div>
                  {/* @Row content */}
                </>
              );
            })}
          </div>
        </div>
      </div>
      {showModalConfirmation && (
        <AlertModal
          isShowed={showModalConfirmation}
          title={`The user will be changed to:`}
          titleRegular={true}
          msg={userForChange.name}
          msgBold={true}
          action={changeUser}
          textButton={"Confirm"}
          textButtonAlertColor={false}
          cancelButton={true}
          cancelTextButton={"Cancel"}
          cancelButtonAlertColor={true}
          actionCancelButton={() => setShowModalConfirmation(false)}
          maxHeight={'auto'}
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    authentication_token: state.session.authentication_token,
    user: state.session.user,
    internalization: state.session.internalization,
    assigned_users: state.session.assigned_users
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getFilteredDataBarsGraph: (authentication_token, url, success, error) =>
      dispatch(
        getFilteredDataBarsGraph(authentication_token, url, success, error),
      ),
    getRecentOrdersActivities: (
      authentication_token,
      page,
      url,
      success,
      error,
    ) => 
      dispatch(
        getRecentOrdersActivities(
          authentication_token,
          page,
          url,
          success,
          error,
        ),
      ),
    selectUser: (authentication_token, success, error, id) =>
      dispatch(selectUser(authentication_token, success, error, id)),
    postCleanNotificationsAdeppar: (authentication_token) => dispatch(postCleanNotificationsAdeppar(authentication_token)),
    createFavorite: (authentication_token, success, error, id) => dispatch(createFavorite(authentication_token, success, error, id)),
    destroyFavorite: (authentication_token, success, error, id) => dispatch(destroyFavorite(authentication_token, success, error, id)),
    createNote: (authentication_token, success, error, id, notes) => dispatch(createNote(authentication_token, success, error, id, notes)),
    destroyNote: (authentication_token, success, error, id, notes) => dispatch(destroyNote(authentication_token, success, error, id, notes)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardTable);
