// import "react-datepicker/dist/react-datepicker.css";
import "react-phone-number-input/style.css";

import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";

//Components
import LineHighChart from "./LineHighChart";
import PieHighcharts from "./PieHighcharts";
import Table from "./Table";
import TableAlternatives from "./TableAlternatives";
import TableDropdown from "./TableDropdown";
import TableRecentOrders from "./TableRecentOrders";
import Tabs from "./Tabs";
import ContentBlock from "./contentBlock";
import Calendar from "./icons/calendar";
import Pagination from "./pagination";
import TableBlock from "./tableBlock";
import TableMultipleDropdown from "./TableMultipleDropdown";
import Modal from "./modal";
import Close from "./icons/close";
import FinancialStatements from '../screens/financialStatements.js'
import Icon from "./icons/";

//tempfile
// import {
//   dataTable1,
//   dataTable2,
//   dataTable3,
//   dataTableDropdown,
//   dataTableAlternatives,
//   dataTableRecentOrders,
//   dataChart1,
//   dataChart2,
//   dataChart3,
//   dataChartCustodian,
//   dataChartAlternatives,
// } from "../helpers/dataTablesTemp";
const moment = require("moment");
let indexRow = -1;

// =========================================================
// FAKE TAB CHILDREN

const fakeTabChildren = [
  {
    code: "Top 10 Holdings",
    title: "Top 10 Holdings",
    isChild: "Assest Alocation",
  },
  {
    code: "Assest Class",
    title: "Assest Class",
    isChild: "Assest Alocation",
  },
  {
    code: "Assest Type",
    title: "Assest Type",
    isChild: "Assest Alocation",
  },
  {
    code: "Equity",
    title: "Equity",
    isChild: "Assest Alocation",
  },
  {
    code: "Fixed Income",
    title: "Fixed Income",
    isChild: "Assest Alocation",
  },
];
const fakeTabs = [
  {
    code: "main",
    title: "Consolidated",
  },
  {
    code: "custodian",
    title: "Custodian",
  },
  {
    code: "asset_allocation",
    title: "Assest Alocation",
    isParent: true,
    children: fakeTabChildren,
  },
  {
    code: "publicVSNonPublicSecurities",
    title: "Public vs Non Public Investments",
  },
  {
    code: "alternatives",
    title: "Alternatives",
  },
  {
    code: "liabilities_addepar",
    title: "Liabilities",
  },
  {
    code: "activity",
    title: "Activity",
  },
];
// =========================================================

const FinancialAssetsTabs = (props) => {
  const [step, setStep] = useState("main");
  const [restartChild, setRestartChild] = useState(false);
  const [showPagination, setShowPagination] = useState(false);
  const [pieSelectedElement, setPieSelectedElement] = useState(-1);
  const [pieChart, setPieChart] = useState(false);
  const [pieSliceSelected, setPieSliceSelected] = useState(false);
  const [searchText, setSearchText] = useState({});
  const [tabActive, setTabActive] = useState(props.tabActive);
  const [tabActiveChildren, setTabActiveChildren] = useState(0);
  const [fromSearch, setFromSearch] = useState(undefined);
  const [fromSearchText, setFromSearchText] = useState(undefined);
  const [date, setDate] = useState(props.date);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showAdvice, setShowAdvice] = useState(false);

  const buttonsDropdownRef = useRef(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(props.totalPages);

  const FA_TABS = props.dataTable.tabs;

  useEffect(() => {
      props.fetchFromHistorical(date);

    console.log("Custodian: => ", props.dataTable.custodian)

    let historical_advice = localStorage.getItem("historical_advice");

    if(historical_advice === 'yes'){
      setShowAdvice(false);
    }else {
      setShowAdvice(true);
      localStorage.setItem("historical_advice", "yes");
    }
    
      // let url = window.location.href.split("&")[0];
      // let prepare_search = []
      // prepare_search.push("code="+step)

      // if (date !== "" && date !== undefined){
      //   prepare_search.push("historical_date="+date)
      //   prepare_search = "&"+prepare_search.join("&")
      //   console.log("prepare_search", prepare_search)
      //   window.history.replaceState({}, "bt360", `${url}${prepare_search}`);

      // }
  }, [date]);

  useEffect(() => {
    if(props.date !== undefined && props.date !== "" && date !== props.date && date !== null && props.date !== "null"){
      setDate(props.date)
    }
  }, [props.date]);


  useEffect(() => {
    if(step === 'financial_statements'){
      props.cleanStatementNotification()
    }
  }, [step])

  const changeStepTab = (tab, index, isChildren = false) => {
    if(tab === null && index !== -1 && index !== null){
      let selected = FA_TABS[index]
      if (selected && selected.isParent) {
        let children_code = selected.children[0].code
        setStep(children_code);
        props.setStep(children_code);
        setRestartChild(true)
        setTabActiveChildren(0);
        setTabActive(index)
        return
      }
    }
    // if(tab && tab.code === "financial_statements_historical") {
    //   props.history.push('/financial-statements')
    // }
    if (isChildren) {
      setTabActiveChildren(index);
      setStep(tab.code);
      props.setStep(tab.code);
    } else {
      setTabActiveChildren(0);
      setRestartChild(false)
      if(index != -1) {
        setTabActive(index);
      }
      if (FA_TABS[index] === undefined) {
        return;
      }
      if (tab && tab.isParent) {
        let children_code = tab.children[0].code
        setStep(children_code);
        props.setStep(children_code);
        setRestartChild(true)
      } else {
        pieChart && resetPieChart();
        setPieSelectedElement(-1);
        setStep(FA_TABS[index].code);
        props.setStep(FA_TABS[index].code);
      }
      // window.history.pushState(
      //   "",
      //   "",
      //   "balance-show?url=balance_principal/financial_assets&code=" +
      //   FA_TABS[index].code
      // );      
    }

    pieChart && resetPieChart();
    setPieSelectedElement(-1);
    if (tab) {
      clearSearch();
    }
    setPieChart(null);
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.has("code")) {
      let tab_index = FA_TABS.findIndex((tab) => tab.code == urlParams.get("code"));
      changeStepTab(null, tab_index);
    }
    if (urlParams.has("search")) {
      setFromSearch(urlParams.get("search"));
    }

    if (urlParams.has("query")) {
      setFromSearchText(urlParams.get("query"));
    }
  }, []);

  useEffect(() => {
    changeStepTab(null, props.tab_index);
  }, [props.tab_index]);

  useEffect(() => {
    setTimeout(() => {
      if (step === "activity") {
        setShowPagination(true);
        props.showPagination(true);
      } else if (showPagination) {
        setShowPagination(false);
        props.showPagination(false);
      }
    }, 0);
  }, [step]);

  const resetPieChart = () => {
    pieChart.series.userOptions.data.forEach((item) => {
      if (item.selected) {
        item.slicedOffset = 0;
        item.selected = false;
        item.sliced = false;
        pieChart.sliced = false;
        pieChart.update();
      }
    });
  };

  const _pieSelected = (pie, nested) => {
    nested && setPieSliceSelected(pie.name);
    setPieChart(pie);

    if (indexRow == pie.index) {
      setPieSelectedElement(-1);
      indexRow = -1;
    } else {
      setPieSelectedElement(pie.index);
      indexRow = pie.index;
    }
  };

  const clearSearch = () => {
    setFromSearch(undefined);
    setFromSearchText(undefined);
  };

  console.log(props)
  return (
    <>
      <div className="o-width--100" style={{ position: "relative", overflowX: "hidden" }}>
        <div style={{ display: "flex", zIndex: 9999 }}>
          {step !== "activity" &&
            step !== "documents" &&
            step !== "contacts" &&
            // step !== "financial_statements" &&
            props.tableBlock.preheader && (
              <div
                className={`${
                  date !== null && date !== "null" && date !== ""
                    ? "table-preheader active"
                    : "table-preheader"
                }`}
                style={{ with: "fit-content" }}
              >
                <p dangerouslySetInnerHTML={{ __html: props.tableBlock.preheader }}></p>
              </div>
            )}
          {step !== "activity" &&
            step !== "documents" &&
            step !== "contacts" &&
            // step !== "financial_statements" &&
            props.tableBlock.preheader && (
              <>
                {date && (
                  <div>
                    <div
                      className=""
                      style={{
                        widht: 20,
                        transform: "rotate(-45deg) translate(-3px, 12px)",
                        cursor: "pointer",
                      }}
                      onClick={() => setDate(null)}
                    >
                      <Icon name="Plus" noWrapper={true} />
                    </div>
                  </div>
                )}
                {
                  showAdvice && (
                    <Modal
                      modalType="notificationsModal"
                      isShowed={showAdvice}
                      modalClasses="c-modal__historical-advice-container"
                      bodyClasses="c-modal__historical-advice"
                      // showBackButton
                      backAction={() => {
                        document.body.classList.remove("has-modal");
                        setShowAdvice(false)
                      }}
                      maxHeight={true}
                    >

                      {/* <span className="tooltip-title">{value.title}</span> */}
                      {props.language.includes("es") && (
                        <>
                          <h4>Datos históricos</h4>
                          <p>
                            Ahora puedes consultar la posición histórica de tu cartera haciendo
                            click en el calendario y eligiendo una fecha.
                            <br />
                            <br />
                            Todos los datos de los Financial Assets se mostraran al cierre de mes
                            de la fecha seleccionada.
                            <br />
                            <br />
                            Accede a través del ícono del calendario <span style={{marginLeft: 10}}><Calendar width={25} height={25} /></span>
                          </p>
                        </>
                      )}
                      {!props.language.includes("es") && (
                        <>
                          <h4>Historical data</h4>
                          <p>
                            Now you can check the historical position of your portfolio clicking
                            in the calendar and choosing a date.
                            <br />
                            <br />
                            All Financial Assets data will be displayed as of the month-end close
                            of the selected date.
                            <br />
                            <br />
                            Access through the calendar icon <span style={{marginLeft: 10}}><Calendar width={25} height={25} /></span>
                          </p>
                        </>
                      )}
                    </Modal>

                  )
                }

                {true && (
                  <div className="c-calendar-datepicker">
                    <button className="c-calendar-datepicker__button">
                      <Calendar width={32} height={32} />
                    </button>
                    <button
                      className="c-calendar-datepicker__info"
                      onClick={(e) => setShowTooltip(!showTooltip)}
                    >
                      <Icon name="InfoTooltip" noWrapper={true} color="white" />


                      {showTooltip && (
                        <div
                          className="container-tooltip container-tooltip-historical top container-tooltip--no-triangle"
                          onClick={(e) => setShowTooltip(!showTooltip)}
                        >
                          {/* <span className="tooltip-title">{value.title}</span> */}
                          {props.language.includes("es") && (
                            <>
                              <h4>Datos históricos</h4>
                              <h5>
                                Puedes consultar el estado de tu portafolio al cierre de un mes
                                concreto
                              </h5>
                              <ul>
                                <li>
                                  Al dar click en el aspa, se regresará a los datos de la fecha más
                                  reciente.
                                </li>
                                <li>
                                  Al salir de la sección de Activos Financieros, se borrará el
                                  filtro y se regresará a los datos de la fecha más reciente.
                                </li>
                              </ul>
                            </>
                          )}
                          {!props.language.includes("es") && (
                            <>
                              <h4>Historical data</h4>
                              <h5>
                                Check the historical position of your portfolio according to the
                                monthly close
                              </h5>
                              <ul>
                                <li>
                                  By clicking on the cross, you will return to the most recent date
                                  data
                                </li>
                                <li>
                                  Exiting the Financial Assets section will clear the filter and
                                  return you to the most recent date data.
                                </li>
                              </ul>
                            </>
                          )}
                        </div>
                      )}
                    </button>
                    <DatePicker
                      onChange={(date) => setDate(moment(date).format("YYYY-MM-DD"))}
                      selected={
                        date !== "null" && date !== null && date !== ""
                          ? new Date(props.tableBlock.preheader_new.date)
                          : new Date()
                      }
                      showMonthYearPicker
                      excludeDates={props.dataTable.disabled_dates.map((d) => new Date(d))}
                      dateFormat="MM/yyyy"
                      minDate={new Date("2018-01-01")}
                      // isClearable
                    />
                  </div>
                )}
              </>
            )}
        </div>

        <Tabs
          tabs={FA_TABS.filter((tab) => !tab.dropdown)}
          onClick={(tab, index, isChildren) => changeStepTab(tab, index, isChildren)}
          tabActive={tabActive}
          tabActiveChildren={tabActiveChildren}
          date={date}
          restartChild={restartChild}
          hasHistorical={true}
        />

        {step === "main" && (
          <div className="c-tabs__content is-selected">
            <div className="c-tabs__content-top">
              <ContentBlock
                header={false}
                maxHeight={false}
                className="centered-and-full"
                wrapperStyle={{ marginRight: 0 }}
              >
                <TableBlock data={props.tableBlock} noTruncate={true} noMargin={true} />
              </ContentBlock>
            </div>
          </div>
        )}

        <div className="c-tabs__content-bottom">
          {step === "main" &&
            !(date !== "null" && date !== null && date !== "") &&
            props.consolidated_graph &&
            props.consolidated_graph.last_month &&
            props.consolidated_graph.last_month.length > 0 &&
            Object.keys(props.consolidated_graph).length > 0 && (
              <LineHighChart
                key={"graph_t"}
                type="account"
                updateData={(itemsSelected, data_temp) => console.log(itemsSelected, data_temp)}
                currentData={props.consolidated_graph.last_month}
                dates={props.consolidated_graph.dates}
                allCustodians={{}}
                accountData={props.consolidated_graph}
                currentCustodianName={
                  props.language.includes("es") ? "Consolidado" : "Consolidated"
                }
                centerLabel={true}
                languageUserAgent={props.language}
                yAxisTitle={props.language.includes("es") ? "Rendimiento" : "Performance"}
                setFilterType={(type) => console.log(type)}
                extra_title={{}}
              />
            )}
        </div>

        {step === "top10Holding" && (
          <div className="c-tabs__content is-selected">
            <div className="c-tabs__content-top">
              <Table
                key={"top10_" + pieSelectedElement + "_" + props.date}
                selectedIndex={pieSelectedElement}
                dataTable={props.dataTable.top_10.table}
                forceShow={true}
                fromSearch={fromSearch}
                fromSearchText={fromSearchText}
                clearSearch={() => clearSearch()}
                searchText={searchText["top_10"]}
                search={(text) =>
                  props.paginateTab(
                    { text: text, source: "top_10", page: 1, type: "get_top10" },
                    () => setSearchText({ ...searchText, ["top_10"]: text }),
                    () => "",
                  )
                }
                history={props.history}
                onClickSecurity={(id) => props.onClickSecurity(id)}
                security_info={props.security_info}
              />
            </div>

            <div className="c-tabs__content-bottom">
              <PieHighcharts
                pieSelected={(pie) => _pieSelected(pie)}
                data={props.dataTable.top_10.graph}
              />
            </div>
          </div>
        )}

        {step === "bonds" && (
          <div className="c-tabs__content is-selected">
            <div className="c-tabs__content-top">
              <Table
                key={"bonds_" + pieSelectedElement}
                selectedIndex={pieSelectedElement}
                dataTable={props.dataTable.bonds.table}
                forceShow={true}
                fromSearch={fromSearch}
                fromSearchText={fromSearchText}
                clearSearch={() => clearSearch()}
                searchText={searchText["top_10"]}
                search={(text) =>
                  props.paginateTab(
                    { text: text, source: "bonds", page: 1, type: "get_bonds" },
                    () => setSearchText({ ...searchText, ["bonds"]: text }),
                    () => "",
                  )
                }
                history={props.history}
                onClickSecurity={(id) => props.onClickSecurity(id)}
                security_info={props.security_info}
              />
            </div>

            <div className="c-tabs__content-bottom">
              <PieHighcharts
                pieSelected={(pie) => _pieSelected(pie)}
                data={props.dataTable.bonds.graph}
              />
            </div>
          </div>
        )}

        {step === "stocks" && (
          <div className="c-tabs__content is-selected">
            <div className="c-tabs__content-top">
              <Table
                key={"stocks_" + pieSelectedElement}
                selectedIndex={pieSelectedElement}
                dataTable={props.dataTable.stocks.table}
                forceShow={true}
                fromSearch={fromSearch}
                fromSearchText={fromSearchText}
                clearSearch={() => clearSearch()}
                searchText={searchText["top_10"]}
                search={(text) =>
                  props.paginateTab(
                    { text: text, source: "stocks", page: 1, type: "get_stocks" },
                    () => setSearchText({ ...searchText, ["stocks"]: text }),
                    () => "",
                  )
                }
                history={props.history}
                onClickSecurity={(id) => props.onClickSecurity(id)}
                security_info={props.security_info}
              />
            </div>

            <div className="c-tabs__content-bottom">
              <PieHighcharts
                pieSelected={(pie) => _pieSelected(pie)}
                data={props.dataTable.stocks.graph}
              />
            </div>
          </div>
        )}

        {step === "assetClass" && (
          <div className="c-tabs__content is-selected">
            <div className="c-tabs__content-top">
              <TableDropdown
                fromSearch={fromSearch}
                fromSearchText={fromSearchText}
                clearSearch={() => clearSearch()}
                key={"assetClass" + pieSelectedElement + "_" + props.date}
                selectedIndex={pieSelectedElement}
                dataTable={props.dataTable.asset_class.table}
                pieSliceSelected={pieSliceSelected}
                // search={(text) => console.log(text)}
                onClickSecurity={(id) => props.onClickSecurity(id)}
                security_info={props.security_info}
                history={props.history}
              />
            </div>

            <div className="c-tabs__content-bottom">
              <PieHighcharts
                pieSelected={(pie) => _pieSelected(pie, true)}
                data={props.dataTable.asset_class.graph}
              />
            </div>
          </div>
        )}

        {step === "assetType" && (
          <div className="c-tabs__content is-selected">
            <div className="c-tabs__content-top">
              <TableDropdown
                fromSearch={fromSearch}
                fromSearchText={fromSearchText}
                clearSearch={() => clearSearch()}
                key={"assetType" + pieSelectedElement + "_" + props.date}
                selectedIndex={pieSelectedElement}
                dataTable={props.dataTable.asset_type.table}
                pieSliceSelected={pieSliceSelected}
                // search={(text) => console.log(text)}
                history={props.history}
                onClickSecurity={(id) => props.onClickSecurity(id)}
                security_info={props.security_info}
              />
            </div>

            <div className="c-tabs__content-bottom">
              <PieHighcharts
                pieSelected={(pie) => _pieSelected(pie, true)}
                data={props.dataTable.asset_type.graph}
              />
            </div>
          </div>
        )}

        {step === "publicVSNonPublicSecurities" && (
          <div className="c-tabs__content is-selected">
            <div className="c-tabs__content-top">
              <Table
                key={"publicVSNonPublicSecurities" + pieSelectedElement + "_" + props.date}
                selectedIndex={pieSelectedElement}
                fromSearch={fromSearch}
                dataTable={props.dataTable.public_non_public.table}
                forceShow={true}
              />
            </div>
            <div className="c-tabs__content-bottom">
              <PieHighcharts
                pieSelected={(pie) => _pieSelected(pie)}
                data={props.dataTable.public_non_public.graph}
              />
            </div>
          </div>
        )}

        {step === "liabilities_addepar" && (
          <div className="c-tabs__content is-selected">
            <div className="c-tabs__content-top">
              <div
                style={{ textAlign: "center", color: "#80abba" }}
                dangerouslySetInnerHTML={{ __html: props.dataTable.liabilities_addepar.table.text }}
              ></div>
              <TableBlock
                id_key={"liabilities_addepar_table" + "_" + props.date}
                data={props.dataTable.liabilities_addepar.table}
                lengthShowActive={props.dataTable.liabilities_addepar.table.rows.length > 15}
                notScroll={true}
                showBtn={props.dataTable.liabilities_addepar.table.rows.length > 15}
                secondTable={false}
                detail={true}
                history={props.history}
              />
            </div>
          </div>
        )}

        {step === "custodian" && (
          <div className="c-tabs__content is-selected">
            <div className="c-tabs__content-top">
              <TableDropdown
                fromSearch={fromSearch}
                fromSearchText={fromSearchText}
                clearSearch={() => clearSearch()}
                key={"custodian" + pieSelectedElement + "_" + props.date}
                selectedIndex={pieSelectedElement}
                dataTable={props.dataTable.custodian.table}
                pieSliceSelected={pieSliceSelected}
                // search={(text) => console.log(text)}
                history={props.history}
                isFinancial={true}
              />
            </div>

            <div className="c-tabs__content-bottom">
              {step === "custodian" &&
                props.dataTable.custodian.graph_all &&
                Object.keys(props.dataTable.custodian.graph_custodian).length > 0 && (
                  <LineHighChart
                    key={"graph_t"}
                    type="custodian"
                    allSelected={props.dataTable.custodian.custodian_all_name}
                    preSelected={false}
                    // updateData={(itemsSelected, data_temp) => this.setState({itemsSelected: itemsSelected, graph_key: "graph_"+itemsSelected})}
                    updateData={(itemsSelected, data_temp) => ""}
                    currentData={props.dataTable.custodian.graph_custodian.dates.last_month}
                    dates={props.dataTable.custodian.graph_custodian.dates}
                    allCustodians={props.dataTable.custodian.graph_all}
                    currentCustodianName={""}
                    centerLabel={true}
                    languageUserAgent={props.language}
                    yAxisTitle={props.language.includes("es") ? "Rendimiento" : "Performance"}
                    extra_title={props.dataTable.custodian.extra_title}
                  />
                )}
              <PieHighcharts
                pieSelected={(pie) => _pieSelected(pie, true)}
                data={props.dataTable.custodian.graph}
              />
            </div>
          </div>
        )}

        {step === "alternatives" && (
          <div className="c-tabs__content is-selected">
            <div className="c-tabs__content-top">
                <TableMultipleDropdown 
                  key={"custodian_"+date} 
                  selectedIndex={-1} 
                  date={date}
                  dataTable={props.dataTable.alternatives.table} 
                  pieSliceSelected={-1}
                  history={props.history}   
                  setMainTab={() => {}}                                      
                  // search={(text)=> console.log(text)}
                />
            
              
            </div>

            <div className="c-tabs__content-bottom">
              <PieHighcharts
                pieSelected={(pie) => _pieSelected(pie, true)}
                data={props.dataTable.alternatives.graph}
              />
            </div>
          </div>
        )}

        {step === "financial_statements" && (
          <div className="c-tabs__content is-selected">
            <div className="c-tabs__content-top">
              <div className="c-tabs__content-top">
                <Table 
                key={"documents"+2+"search"+"a"}            
                dataTable={props.dataTable.financial_statements.table.table}
                showSearcher={false}                             
                action={{
                  title: "Acciones",
                  show: (el) => props.showDocument(el.url, false),
                  edit: (el) => props.showDocument(el.url, true)
                }} 
                searchText={""}
                search={(text)=> ""}
                all_ellipsis={true} 
                hasFirstColumnBig={true}
                marginRightFirstColumn={true}
              />
              </div>
            </div>
          </div>
        )}

        {
          step === "financial_statements_historical" && <FinancialStatements props={props}/>
        }
        {/*step === "financial_statements" && props.financial_statements_more_data && (
          <div onClick={() => props.history.push('/financial-statements')} style={{cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent:'center', paddingTop: 8}}>
              <Icon
              name="Link"
              color={"#005774"}
              width={"16px"}
              height={"16px"}
            />
            <p className="headingH4">
            {
              props.language.includes("es") ? 'Acceso a los Reportes Históricos' : 'Access to the Historical Reports'
            }</p>
          </div>
          
        )*/}
        {step === "activity" && (
          <div className="c-tabs__content is-selected">
            <div className="c-tabs__content-top o-overflow--visible">
              <TableRecentOrders
                dataTable={props.recentOrder}
                filters_internalization={props.filters_internalization}
                filteredCall={(params) => props.filteredCall(params)}
                currentPage={props.currentPage}
                totalPages={props.totalPages}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FinancialAssetsTabs;
