// Dependencies
import React, { useState, useEffect } from "react";
import {internalization} from '../constants.js'


const Breadcrumbs = props => {
  // const [special_parent, setSpecialParent] = useState(props.special_parent);
  var languageUserAgent = (/^es/).test(props.user.language) ? 'es' : 'en';
  let urlWithoutBar =
    props.breadcrumbs.assetUrl !== undefined &&
    props.breadcrumbs.assetUrl.lastIndexOf("/") ===
      props.breadcrumbs.assetUrl.length - 1
      ? props.breadcrumbs.assetUrl.slice(0, -1)
      : props.breadcrumbs.assetUrl;
      let ownership_parent = props.special_parent.length > 0 ? props.special_parent.filter(e => e.code !== "") : [{title: internalization[languageUserAgent].models.home, code: "home", url: "/home"}] 
      ownership_parent.push({title: props.breadcrumbs.assetTitle, code: ""})

      // let ownership_parent_show = props.special_parent.length > 0 ? props.special_parent.filter(e => e.code !== "") : [{title: internalization[languageUserAgent].models.home, code: "home", url: "/home"}, {title: internalization[languageUserAgent].models.ownership, code: "ownership-index", url: "/ownership-index"}] 
      let ownership_parent_show = []
      props.breadcrumbs && ownership_parent_show.push({title: internalization[languageUserAgent].models.home, code: "home", url: "/home"}, {title: internalization[languageUserAgent].models.ownership, code: props.breadcrumbs.assetTitle, url: '/ownership-index/'}, {title: props.breadcrumbs.assetTitle, code: "ownership-show", url:props.location.pathname 
      })
  

      let copy_ownership_parent_show = JSON.parse( JSON.stringify( ownership_parent_show ) )
      copy_ownership_parent_show[0]["title"] = props.special_parent.length > 0 &&  copy_ownership_parent_show[0]["code"] + " / " + internalization[languageUserAgent].models.ownership

    let breadcrumbsOptions = {
        "/dashboard": [
            {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
            {title: "Pro", code: ""}        
        ],
        "/wealth-map" : [
          {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
          {title: "Wealth Map", code: ""}        
        ],
        "/services": [
            {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
            {title: internalization[languageUserAgent].models.products_services, code: "products_services", url: "/home"},            
            {title: props.breadcrumbs.assetTitle, code: ""}
        ],
        "/researchs": [
            {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
            {title: internalization[languageUserAgent].models.research, code: "research", url: "/home"},
            {title: props.breadcrumbs.assetTitle, code: ""}
        ],
        "/market": [
            {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
            {title: props.breadcrumbs.assetTitle, code: ""}
        ],        
        "/notifications": [
            {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
            {title: props.breadcrumbs.assetTitle, code: ""}
        ],
        "/alerts": [
            {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
            {title: props.breadcrumbs.assetTitle, code: ""}
        ],        
        "/search": [
            {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
            {title: props.breadcrumbs.assetTitle, code: ""}
        ],
        "/ownership_show": ownership_parent_show,
        "/ownership-index": ownership_parent,
        "/documents-show": [
            {title: internalization[languageUserAgent].models.files, code: "files", url: "/documents-index"},
            {title: props.breadcrumbs.assetTitle, code: ""}
        ],       
        "/valuations": [...props.special_parent, {title: internalization[languageUserAgent].models.valuations, code: ""}],
        "/balance-new": [...props.special_parent, {title: props.breadcrumbs.assetTitle, code: ""}],
        "/balance-edit": [...props.special_parent, {title: props.breadcrumbs.assetTitle, code: ""}],
        "/profile": [
          {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
          {title: internalization[languageUserAgent].models.settings, code: "profile", url: "/profile"},
          {title: props.breadcrumbs.assetTitle, code: ""}
      ],
      "/profile_profile": [
          {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
          {title: internalization[languageUserAgent].models.settings, code: "profile", url: "/profile"},
          {title: props.breadcrumbs.assetTitle, code: ""}
      ],
      "/profile_access": [
            {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
            {title: internalization[languageUserAgent].models.settings, code: "profile", url: "/profile"},
          {title: props.breadcrumbs.assetTitle, code: ""}
      ],
      "/profile_privacy": [
            {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
            {title: internalization[languageUserAgent].models.settings, code: "profile", url: "/profile"},
          {title: props.breadcrumbs.assetTitle, code: ""}
      ],
      "/profile_manuals": [
            {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
            {title: internalization[languageUserAgent].models.settings, code: "profile", url: "/profile"},
          {title: props.breadcrumbs.assetTitle, code: ""}
      ],      
      "/profile_cm": [
            {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
            {title: internalization[languageUserAgent].models.settings, code: "profile", url: "/profile"},
          {title: props.breadcrumbs.assetTitle, code: "content_manager", url: "/profile?code=cm"}
      ],
      "/profile_settings": [
            {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
            {title: internalization[languageUserAgent].models.settings, code: "profile", url: "/profile"},
          {title: props.breadcrumbs.assetTitle, code: ""}
      ],       
      "balance_principal/receivable_accounts": [
        {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
         {title: internalization[languageUserAgent].models.receivable_account, code: "receivable_account", url: "/balance-show?url=balance_principal/receivable_accounts"},
         {title: props.breadcrumbs.assetTitle, code: ""}
      ],
      "balance_principal/financial_assets": [
          {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
         {title:  internalization[languageUserAgent].models.financial_assets, code: "financial_assets", url: "/balance-show?url=balance_principal/financial_assets"},
         {title: props.breadcrumbs.assetTitle, code: ""}
      ],
      "balance_principal/financial_assets/documents": [
          {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
         {title:  internalization[languageUserAgent].models.financial_assets, code: "financial_assets", url: "/balance-show?url=balance_principal/financial_assets"},
         {title: internalization[languageUserAgent].models.documents, code: ""}
      ],
      "balance_principal/financial_assets/contacts": [
          {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
         {title:  internalization[languageUserAgent].models.financial_assets, code: "financial_assets", url: "/balance-show?url=balance_principal/financial_assets"},
         {title: internalization[languageUserAgent].models.contacts, code: ""}
      ],
      "balance_principal/financial_assets/alerts": [
          {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
         {title:  internalization[languageUserAgent].models.financial_assets, code: "financial_assets", url: "/balance-show?url=balance_principal/financial_assets"},
         {title: internalization[languageUserAgent].models.alerts, code: ""}
      ],
      "balance_principal/financial_assets/financial_statements": [
          {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
         {title:  internalization[languageUserAgent].models.financial_assets, code: "financial_assets", url: "/balance-show?url=balance_principal/financial_assets"},
         {title: internalization[languageUserAgent].models.financial_statements, code: ""}
      ],
      "balance_principal/fixed_assets": [
        {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
         {title: internalization[languageUserAgent].models.fixed_assets, code: "fixed_assets", url: "/balance-show?url=balance_principal/fixed_assets"},
         {title: props.breadcrumbs.assetTitle, code: ""}
      ],
      // "balance_principal/custodians": [
      //     {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
      //     // ...props.special_parent,
      //    {title:  internalization[languageUserAgent].models.financial_assets, code: "financial_assets", url: "/balance-show?url=balance_principal/financial_assets"},
      //    {title:  internalization[languageUserAgent].models.custodians, code: "custodians", url: "/balance-show?url=balance_principal/financial_assets&code=custodian"},                   
      //    {title: props.breadcrumbs.assetTitle, code: ""}
      // ],  
      "balance_principal/custodians": [
          ...props.special_parent,
         {title: props.breadcrumbs.assetTitle, code: ""}
      ],    
      "balance_principal/accounts": [
          ...props.special_parent,
         {title: props.breadcrumbs.assetTitle, code: ""}
      ],      

      "balance_principal/real_estates": [
        {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
        {title: internalization[languageUserAgent].models.fixed_assets, code: "fixed_assets", url: "/balance-show?url=balance_principal/fixed_assets"},
        {title: internalization[languageUserAgent].models.real_estates, code: "real_estates", url: "/balance-show?url=balance_principal/real_estates"},
         {title: props.breadcrumbs.assetTitle, code: ""}
      ],
      "balance_principal/direct_investments": [
        {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
        {title: internalization[languageUserAgent].models.fixed_assets, code: "fixed_assets", url: "/balance-show?url=balance_principal/fixed_assets"},
        {title: "Direct Investment", code: "direct_investments", url: "/balance-show?url=balance_principal/direct_investments"},
         {title: props.breadcrumbs.assetTitle, code: ""}
      ],
      "balance_principal/collections/home": [
        {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
        {title: internalization[languageUserAgent].models.fixed_assets, code: "fixed_assets", url: "/balance-show?url=balance_principal/fixed_assets"},
        {title: internalization[languageUserAgent].models.collections, code: "collections", url: "/balance-show?url=balance_principal/collections/home"},
        
         {title: props.breadcrumbs.assetTitle, code: ""}
      ],
      "balance_principal/collections/vehicles": [
        {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
        {title: internalization[languageUserAgent].models.fixed_assets, code: "fixed_assets", url: "/balance-show?url=balance_principal/fixed_assets"},
        {title: internalization[languageUserAgent].models.collections, code: "collections", url: "/balance-show?url=balance_principal/collections/home"},
        {title: internalization[languageUserAgent].models.vehicles, code: "vehicles", url: "/balance-show?url=balance_principal/collections/vehicles"},
         {title: props.breadcrumbs.assetTitle, code: ""}
      ],
      "balance_principal/collections/watches": [
        {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
        {title: internalization[languageUserAgent].models.fixed_assets, code: "fixed_assets", url: "/balance-show?url=balance_principal/fixed_assets"},
        {title: internalization[languageUserAgent].models.collections, code: "collections", url: "/balance-show?url=balance_principal/collections/home"},
        {title: internalization[languageUserAgent].models.watches, code: "watches", url: "/balance-show?url=balance_principal/collections/watches"},
         {title: props.breadcrumbs.assetTitle, code: ""}
      ],
      "balance_principal/collections/arts": [
        {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
        {title: internalization[languageUserAgent].models.fixed_assets, code: "fixed_assets", url: "/balance-show?url=balance_principal/fixed_assets"},
        {title: internalization[languageUserAgent].models.collections, code: "collections", url: "/balance-show?url=balance_principal/collections/home"},
        {title: internalization[languageUserAgent].models.arts, code: "arts", url: "/balance-show?url=balance_principal/collections/arts"},
         {title: props.breadcrumbs.assetTitle, code: ""}
      ],
      "balance_principal/others": [
        {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
        {title: internalization[languageUserAgent].models.others, code: "other", url: "/balance-show?url=balance_principal/others"},
        {title: props.breadcrumbs.assetTitle, code: ""}
      ],
      "balance_principal/bank_accounts": [
        {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
        {title: internalization[languageUserAgent].models.bank_accounts, code: "bank_accounts", url: "/balance-show?url=balance_principal/bank_accounts"},
         {title: props.breadcrumbs.assetTitle, code: ""}
      ],
      "balance_principal/deferred_assets/home": [
        {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
         {title: internalization[languageUserAgent].models.deferred_assets, code: "deferred_assets", url: "/balance-show?url=balance_principal/deferred_assets/home"},
         {title: props.breadcrumbs.assetTitle, code: ""}
      ],
      "balance_principal/deferred_assets/life_insurances": [
        {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
        {title: internalization[languageUserAgent].models.deferred_assets, code: "deferred_assets", url: "/balance-show?url=balance_principal/deferred_assets/home"},
        // {title: internalization[languageUserAgent].models.life_insurances, code: "life_insurances", url: "/balance-show?url=balance_principal/deferred_assets/life_insurances"},
         {title: props.breadcrumbs.assetTitle, code: ""}
      ],
      "/balance_principal/deferred_assets/life_insurances": [
        {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
        {title: internalization[languageUserAgent].models.deferred_assets, code: "deferred_assets", url: "/balance-show?url=balance_principal/deferred_assets/home"},
        // {title: internalization[languageUserAgent].models.life_insurances, code: "life_insurances", url: "/balance-show?url=balance_principal/deferred_assets/life_insurances"},
         {title: props.breadcrumbs.assetTitle, code: ""}
      ],
      "balance_principal/deferred_assets/others": [
          {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
          {title: internalization[languageUserAgent].models.deferred_assets, code: "deferred_assets", url: "/balance-show?url=balance_principal/deferred_assets/home"},
          {title: internalization[languageUserAgent].models.others, code: "others", url: "/balance-show?url=balance_principal/deferred_assets/others"},
          {title: props.breadcrumbs.assetTitle, code: ""}
      ],
      "/balance-index": [
          {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
         {title: internalization[languageUserAgent].models.balance, code: "balance"},
      ], 
      "balance_principal": [
          {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
          {title:  internalization[languageUserAgent].models.financial_assets, code: "financial_assets", url: "/balance-show?url=balance_principal/financial_assets&code=alternatives"},
         {title: props.breadcrumbs.assetTitle, code: ""}      
        ],           
      
      "balance_principal/liabilities": [
        {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
         {title: internalization[languageUserAgent].models.liabilities, code: "liabilities", url: "/balance-show?url=balance_principal/liabilities"},
         {title: props.breadcrumbs.assetTitle, code: ""}
      ],

      "/balance_principal/liabilities": [
        {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
         {title: internalization[languageUserAgent].models.liabilities, code: "liabilities", url: "/balance-show?url=balance_principal/liabilities"},
         {title: props.breadcrumbs.assetTitle, code: ""}
      ],

      "balance_principal/liability_addepars": [
        {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
         {title:  internalization[languageUserAgent].models.financial_assets, code: "financial_assets", url: "/balance-show?url=balance_principal/financial_assets"},        
         {title: internalization[languageUserAgent].models.liabilities, code: "liabilities", url: "/balance-show?url=balance_principal/liabilities"},
         {title: props.breadcrumbs.assetTitle, code: ""}
      ],

      "/balance_principal/liability_addepars": [
        {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
         {title:  internalization[languageUserAgent].models.financial_assets, code: "financial_assets", url: "/balance-show?url=balance_principal/financial_assets"},        
         {title: internalization[languageUserAgent].models.liabilities, code: "liabilities", url: "/balance-show?url=balance_principal/liabilities"},
         {title: props.breadcrumbs.assetTitle, code: ""}
      ],
      
      "/financial-statements": [
        {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
        {title:  internalization[languageUserAgent].models.financial_assets, code: "financial_assets", url: "/balance-show?url=balance_principal/financial_assets&code=financial_statements"},
        {title: internalization[languageUserAgent].models.financial_statements, code: ""}      
      ]
    };



 const _checkSpecialParent = () => {
    //breadcrumbsOptions[urlWithoutBar]
  };
  const _breadcrumbsText = () => {
    console.log("urlWithoutBar", urlWithoutBar)
    console.log("props.breadcrumbs.url", props.breadcrumbs.url)
    console.log(breadcrumbsOptions[urlWithoutBar])
    return {
      assetUrl: breadcrumbsOptions[urlWithoutBar],
      url: props.breadcrumbs.url
    };
  };



  const _onClick = (crumb, index, breadcrumbsFiltered) => {    
    if(breadcrumbsFiltered[index].url !== undefined){

      if(breadcrumbsFiltered[index].url === props.history.location.pathname){
        // props.history.(breadcrumbsFiltered[index].url)
        if(crumb.code === "profile"){
          window.location.href = "/profile"
        }
        else{
          window.location.reload()  
        }
        
      }
      else{

        if(crumb.code !== "home" && props.special_parent.length > 1){
          
          let position_current = props.special_parent.findIndex(element => element.code === crumb.code);
          
          if(position_current !== -1) props.setSpecialParent(props.special_parent.slice(0, position_current))  
        }
        
          props.history.push(breadcrumbsFiltered[index].url) 
      }      
    }
    if(crumb.code === "main_service"){
     window.location.reload()  
    }    
    // if(breadcrumbsOptions[urlWithoutBar][index].url !== undefined){

    //   if(breadcrumbsOptions[urlWithoutBar][index].url === props.history.location.pathname){
    //     // props.history.(breadcrumbsOptions[urlWithoutBar][index].url)
    //     if(crumb.code === "profile"){
    //       window.location.href = "/profile"
    //     }
    //     else{
    //       window.location.reload()  
    //     }
        
    //   }
    //   else{

    //     if(crumb.code !== "home" && props.special_parent.length > 1){
          
    //       let position_current = props.special_parent.findIndex(element => element.code === crumb.code);
          
    //       if(position_current !== -1) props.setSpecialParent(props.special_parent.slice(0, position_current))  
    //     }
        
    //     props.history.push(breadcrumbsOptions[urlWithoutBar][index].url)  
    //   }      
    // }
    // if(crumb.code === "main_service"){
    //  window.location.reload()  
    // }
  }
  
  let breadcrumbsFiltered =
    Object.keys(props.breadcrumbs).length > 0 &&
    _breadcrumbsText().assetUrl && _breadcrumbsText().assetUrl.filter(crumb => crumb.title !== undefined);
    
    // props.setCurrentBreadcrumb(breadcrumbsFiltered)
  let breadcrumbsLength =
    props.breadcrumbs && Object.keys(props.breadcrumbs).length > 0 && breadcrumbsFiltered && breadcrumbsFiltered.length;

  if(urlWithoutBar !== undefined) props.setCurrentBreadcrumb(breadcrumbsOptions[urlWithoutBar])
  
  if(breadcrumbsFiltered){
    breadcrumbsFiltered = [...new Map(breadcrumbsFiltered.map(item => [item["code"], item])).values()];
     breadcrumbsFiltered = [...new Map(breadcrumbsFiltered.map(item => [item["title"], item])).values()];
  }
  console.log(breadcrumbsFiltered)
  return (

    <div className="wrapper-breadcrumbs"  >
      
      {Object.keys(props.breadcrumbs).length > 0 &&
        props.location.pathname !== '/alerts' &&
        props.location.pathname !== '/search' &&
        props.location.pathname !== '/notifications' &&
        
        breadcrumbsFiltered && breadcrumbsFiltered.map((crumb, index) => {
          return (
            <span
              onClick={() => _onClick(crumb, index, breadcrumbsFiltered)}
              key={""+Math.random()}
              className={`body ${
                breadcrumbsFiltered.length - 1 === index ? "orangeMedium" : "blue"
              }`}
            >
              {crumb.title} {breadcrumbsFiltered.length - 1 === index ? "" : "/"}{" "}
            </span>
          );
        })}
    </div>
  );
};

export default Breadcrumbs;
