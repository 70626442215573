import { useState, useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";
import StackHeader from "../../components/stackHeader";
import Icon from "../../components/icons";
import PieHighcharts from "../../components/PieHighcharts";
import HeroCard from "./heroCard";
import Loading from "../../components/loading";
import DashboardTable from "./dashboardTable";
import { getDashboard } from "../../actions/dashboard";

const tabs = [
  {
    title: "Blur",
  },
  {
    title: "Platform resources"
  }
]

const Dashboard = (props) => {
  const { language: lang } = props.match.internalization;

  const [tooltip, setTooltip] = useState(null);
  const [loading, setLoading] = useState(true);
  const [lastMarketClose, setLastMarketClose] = useState({});
  const [introCards, setIntroCards] = useState([]);
  const [introCardsClients, setIntroCardsClients] = useState([]);
  const [pieHighChartData, setPieHighChartData] = useState([]);
  const [pieAssetClassData, setPieAssetClassData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const tooltipRef = useRef(null);

  const fetchDashboard = useCallback(() => {
    props
      .getDashboard(props.authentication_token)
      .then((data) => successDashboard(data))
      .catch((err) => errorDashboard(err))
      .finally(() => finallyDashboard());
  }, [props.getDashboard, props.authentication_token]);

  useEffect(() => {
    fetchDashboard();
    hideHeaderMenu();
    return () => hideHeaderMenu(true)
  }, [fetchDashboard]);

  // Hide burger menu items
  useEffect(() => {
    const sidebarItems = document.querySelector(".sidebar-items")
    sidebarItems.style.display = "none"
    return () => {
      sidebarItems.style.display = "flex"
    } 
  }, [])

  // Create new configuation submenu
  useEffect(() => {
    const prefooter = document.querySelector(".sidebar-items.prefooter")
    const firstSeparator = prefooter.querySelector(".item-separator")

    // create new items
    const divBlur = '<button class="wrapper-item blur-button"><p class="title1 item-text blur-text" style="padding-left: 24px;">Blur</p></button><div class="item-separator" style="width: 100%;"></div>'
    const divProfile = '<button class="wrapper-item profile-button"><p class="title1 item-text" style="padding-left: 24px;">Perfil</p></button><div class="item-separator" style="width: 100%;"></div>'
    const divManuals = '<button class="wrapper-item manuals-button"><p class="title1 item-text" style="padding-left: 24px;">Manuales</p></button><div class="item-separator" style="width: 100%;"></div>'
    const configSubmenuContainer = `<div class="config-submenu" style="width: 80%; margin-left: auto; margin-right: 10%;">${[divBlur, divProfile, divManuals].join('')}</div>`

    firstSeparator.insertAdjacentHTML('afterend', configSubmenuContainer)

    document.querySelector(".blur-button").addEventListener('click', handleBlur)
    document.querySelector(".profile-button").addEventListener("click", goToProfile);
    document.querySelector(".manuals-button").addEventListener("click", goToManuals);

    return () => {
      document.querySelector(".blur-button").removeEventListener("click", handleBlur);
      document.querySelector(".profile-button").removeEventListener("click", goToProfile);
      document.querySelector(".manuals-button").removeEventListener("click", goToManuals);
      document.querySelector(".config-submenu").remove()
    };

  }, [])

  const handleBlur = () => {
    const dashboard = document.querySelector('.dashboard')
    const blurText = document.querySelector('.blur-text')
    if (blurText.innerHTML === 'Blur') {
      blurText.innerHTML = 'Unblur'
    } else {
      blurText.innerHTML = 'Blur'
    }
    dashboard.classList.toggle("is-blur")
    // dashboard.classList.remove("is-blur")
  }

  const goToProfile = () => {
    window.location.href = "/profile"
  }

  const goToManuals = () => {
    window.location.href = "/profile?code=manuals"
  }

  const hideHeaderMenu = (leaving) => {
    const menu = document.querySelector(".c-header__content-bottom")
    if(leaving) {
      menu.style.display = "flex";
    } else {
      menu.style.display = "none";
    }
  }

  const successDashboard = (data) => {
    console.log("DATA", data);
    mappedData(data);
    props.getBreadcrumbs({
      assetUrl: "/dashboard",
      url: "/dashboard",
      assetTitle: "Dashboard",
    });
  };

  const errorDashboard = (error) => {
    window.location.href = '/'
  };

  const finallyDashboard = () => {
    setLoading(false);
  };

  const mappedData = (data) => {
    // Last market close
    setLastMarketClose(data.market_closed);

    // Intro cards
    setIntroCardsClients([
      { clients: data.clients },
      { actity_last_30_days: data.actity_last_30_days },
      { all_clients_registered: data.all_clients_registered }
    ]);
    setIntroCards([
      { total_aum: data.total_aum },
      { total_financial_aum: data.total_financial_aum },
      { average_size: data.average_size },
    ]);

    // Pie High Chart
    setPieHighChartData(data.pie_high_charts.data);
    setPieAssetClassData(data.pie_asset_class);

    // Table
    setTableData(data.clients_table);

  };

  const pieSelected = (pie, nested) => {
    console.log(pie, nested);
  };

  const renderTooltip = () => {

    return (
      <div
        ref={tooltipRef}
        style={{
          transition: "all 300ms ease-in-out",
          opacity: 0,
          pointerEvents: "none",
          width: "fit-content",
          maxWidth: 380,
        }}
        className="container-tooltip container-tooltip--no-triangle"
      >
        {tooltip && (
          <p
            style={{ textAlign: "center" }}
            dangerouslySetInnerHTML={{ __html: tooltip.text }}
          ></p>
        )}
      </div>
    );
  };


  const handleChangeTab = (tab, index) => {

    if (index === 0) {

      if (tabs[index].title === 'Blur') {
        tabs[index].title = 'Unblur'

      }else {
        tabs[index].title = 'Blur'
      }
    } 

    if (index === 1) {
      window.open(
        'https://s3.amazonaws.com/bulltick360-staging/user_manuals/contents/000/000/002/bt360_UserManual_ESP_v.1.8.pdf?1684350636',
        '_blank' 
      );
    }

  }


  return (
    <>
      {loading && <Loading />}
      <div className="main-wrapper market">

        <div class="table-preheader" style={{ margin: 0 }}>
          <p>
            <strong>
              <span>{lastMarketClose.title}:</span> {lastMarketClose.amount}
            </strong>
          </p>
        </div>

        <div className="wrapper-cards wrapper-cards--dashboard">
          <div style={{ flex: 1 }}>
            <div className="container-cards grid grid--col-3">

              <div className="card small in-grid" style={{ maxHeight: 'initial', height: '100%' }}>
                {introCardsClients.map((item, index) => {
                  return (
                    <>
                      <p className="card-number">
                        {Object.values(item)[0].amount}
                      </p>
                      <p className="card-text small o-color--blue-dark">{Object.values(item)[0].title}</p>
                    </>
                  )
                })}
              </div>

              {introCards.map((item, index) => {
                return (
                  <HeroCard
                    item={item}
                    lang={lang}
                    setTooltip={(item, ref) => {
                      if (!tooltip) {
                        setTooltip(item.total_aum.tooltip);
                      } else {
                        if (tooltip.title && tooltip.title !== item.title) {
                          setTooltip(item.total_aum.tooltip);
                        } else {
                          setTooltip(null);
                        }
                      }

                      setTimeout(() => {
                        tooltipRef.current.style.opacity = 1;
                        tooltipRef.current.style.top = ref.current.offsetTop + "px";
                        tooltipRef.current.style.left = ref.current.offsetLeft + "px";
                      }, 500);
                    }}
                  />
                );
              })}
            </div>

            {tooltip && renderTooltip(tooltip)}
          </div>

          <div>
            <div style={{ height: 192, maxWidth: 600, margin: "0 auto", overflowY: "hidden", marginBottom: 40 }}>
              <PieHighcharts
                pieSelected={(pie) => pieSelected(pie)}
                data={pieHighChartData}
                size={112}
                chartClassName="c-chart-pie-dashboard"
              />
            </div>

            <div style={{ height: 192, maxWidth: 600, margin: "0 auto", overflowY: "hidden" }}>
              <PieHighcharts
                pieSelected={(pie) => pieSelected(pie)}
                data={pieAssetClassData}
                size={112}
                chartClassName="c-chart-pie-dashboard"
              />
            </div>
          </div>

        </div>

        {tableData.length > 0 && (
          <DashboardTable
            data={tableData}
            history={props.history}
            location={props.location}
            lang={lang}
          />
        )}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    authentication_token: state.session.authentication_token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDashboard: (authentication_token, success, error) =>
      dispatch(getDashboard(authentication_token, success, error)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
